const baseStyle = {
  color: "white",
  zIndex: 999,
};

function variantTitle() {
  return {
    color: "primary.600",
    fontSize: [40, 50, 65, 80],
    fontWeight: "bold",
    lineHeight: 1,
  };
}

function variantTitle2() {
  return {
    color: "primary.600",
    fontSize: [30, 60],
    fontWeight: "bold",
    lineHeight: 1,
  };
}

function variantDescription() {
  return {
    fontSize: [20, 25, 30],
    fontWeight: 400,
  };
}

function variantDescription2() {
  return {
    fontSize: [16, 20, 25],
    fontWeight: 400,
  };
}

function variantDescription3() {
  return {
    color: "white",
    fontSize: [35, 45, 50, 55],
    fontWeight: 1000,
  };
}

function variantDescription4() {
  return {
    color: "primary.800",
    fontSize: [35, 45, 50, 53],
    fontWeight: 800,
  };
}

const variants = {
  title: variantTitle,
  title2: variantTitle2,
  description: variantDescription,
  description2: variantDescription2,
  description3: variantDescription3,
  description4: variantDescription4,
};

const buttonConfig = { baseStyle, variants };

export default buttonConfig;
