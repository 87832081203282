import {
  AspectRatio,
  Box,
  Button,
  Center,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import Carousel from "react-multi-carousel";

const socialProofConfig = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const meetingProofConfig = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const ShowcaseSection: React.FC = () => {
  const videoShowcase = [
    {
      video: "QLRJTcV-NKA",
      personName: "Marco Esselin",
      description: `Conta como a Konnte Vigilância e Segurança, compartilha como a nossa plataforma BR5 Turbo trouxe soluções essenciais e economias reais para sua empresa, cumprindo obrigações legais e, de quebra, gerando lucro!`,
    },
    {
      video: "1WOQkp0pYYY",
      personName: "Rafael Olair",
      description: `Dono da maior distribuidora de gás de Mato Grosso, economizou e renovou sua frota. Hoje, cada botijão de gás deles vai com uma oportunidade: economizar em seguros e serviços através da nossa plataforma BR5`,
    },
    {
      video: "wwfH1vn2UTE",
      personName: "Taciane Torres",
      description: `Gratidão define a experiência de Taciane Torres com a BR5 Turbo! 

      Ela compartilha como a parceria com a BR5 transformou sua trajetória, proporcionando novas oportunidades de crescimento e inovação no setor de seguros. Com uma equipe dedicada e suporte eficiente, Taciane encontrou no BR5 Turbo um caminho sólido para alcançar seus objetivos e conquistar mais resultados.`,
    },
  ];

  const socialProof = [
    "./assets/images/testimonial/1.webp",
    "./assets/images/testimonial/2.webp",
    "./assets/images/testimonial/W6TKDFb.webp",
    "./assets/images/testimonial/3.webp",
  ];

  const meetingProof = [
    "./assets/images/testimonial/mUcoMTy.webp",
    "./assets/images/testimonial/GzoKsVq.webp",
    "./assets/images/testimonial/2U91WV3.webp",
    "./assets/images/testimonial/yCO5SGg.webp",
  ];

  return (
    <>
      <Box
        bg="black"
        w="100vw"
        py={{ base: 14, lg: 20 }}
        position="relative"
        bgImg="./assets/images/bg-showcasesection.webp"
        bgSize="cover"
        bgPosition="center"
        px={{ base: 3, lg: 0 }}
      >
        <Text mb={-10} 
            textAlign="center"
            variant="title2" 
            color="#edae0d" 
            fontFamily={"'Montserrat', sans-serif"}>
          DEPOIMENTOS
        </Text>

        <VStack mb={10}>
          <VStack mt={20}>
            <Text
            fontWeight={0} // Remover o bold
            fontSize={["lg", "xl"]} // Aumentar o tamanho
            letterSpacing="wide" // Deixar mais fino
            textAlign="center"
            w={["90%", "80%", "70%", "60%", "50%"]}
          >
            Com nossa plataforma e metodologia inovadora, já transformamos a vida de mais de 4.732 pessoas, gerando renda vitalícia e proporcionando segurança financeira para o futuro!
          </Text>
          </VStack>
        </VStack>
        
        <SimpleGrid
          justifyContent="space-between"
          px={{ base: 0, lg: 10 }}
          columns={{ base: 1, lg: 3 }}
          w="100%"
        >
          {videoShowcase.map(({ video, personName, description }) => (
            <Box key={video} px={{ base: 5, lg: 10 }}>
              <AspectRatio
                margin="0 auto"
                width={{
                  base: "250px",
                  lg: "280px",
                  xl: "300px",
                  "2xl": "450px",
                }}
                height={{ base: "300px", lg: "300px", xl: "300px" }}
              >
                <iframe
                  src={`https://www.youtube.com/embed/${video}`}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                />
              </AspectRatio>

              <Text
                my={0}
                textAlign="center"
                fontSize={{ base: 24, lg: 32 }}
                letterSpacing="wide"
              >
                {personName}
              </Text>
              <Text
                my={5}
                textAlign="center"
                variant="description"
                fontSize={{ base: 8, lg: 14 }}
              >
                {description}
              </Text>
            </Box>
          ))}
        </SimpleGrid>
        <Center my={{ base: 10, lg: 20 }}>
          <Image 
            src="./assets/images/LOGO_BR5_TURBO.png"
            w={["220px", "350px", "290px", "240px"]}
          />
        </Center>

        {!!socialProof.length && (
          <>
            <Text
              mb={10}
              mt={{ base: 10, lg: 20 }}
              textAlign="center"
              variant="title2"
            >
              Casos reais de Renda gerada
            </Text>

            <Box px={{ base: 5, lg: 20 }}>
              <Carousel
                swipeable={false}
                draggable={false}
                keyBoardControl={true}
                ssr={true}
                infinite={true}
                responsive={socialProofConfig}
              >
                {socialProof.map((link) => (
                  <Center key={link} px={2} mx={3}>
                    <Image src={link} maxW="250px" />
                  </Center>
                ))}
              </Carousel>
            </Box>
          </>
        )}

        {!!meetingProof.length && (
          <>
            <Text my={10} textAlign="center" variant="description">
              Procuramos pessoas que queiram aumentar suas fontes de renda!
            </Text>
            <Text textAlign="center" variant="title2">
              4.732 Vidas já impactadas
            </Text>

            <Box px={5} mt={20}>
              <Carousel
                swipeable={false}
                draggable={false}
                keyBoardControl={true}
                ssr={true}
                infinite={true}
                responsive={meetingProofConfig}
              >
                {meetingProof.map((link) => (
                  <Center key={link} mx={3}>
                    <Image src={link} />
                  </Center>
                ))}
              </Carousel>
            </Box>
          </>
        )}

        <Text mt={14} textAlign="center" variant="description">
          Faça como muitos empresários bem sucedidos e tenha outras fontes de
          renda
        </Text>
      </Box>
    </>
  );
};

export default ShowcaseSection;
